import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core-shared/services/resource.service';
import { ILocalizations } from '@core-shared/models/localization-interfaces';
import { ConfigurationService } from '@core-shared/services/configuration.service';
import { mergeDeep, observableWithFallbacks } from 'inova-shared';

export class CustomTranslateLoaderService extends TranslateHttpLoader {

  private translation?: ILocalizations;

  constructor(private httpClient: HttpClient, private resourceService: ResourceService, private configService: ConfigurationService) {
    super(httpClient);
  }

  public getTranslation(lang: string): Observable<ILocalizations> {
    if (this.translation) {
      return of(this.translation);
    }
    const cultureWithFallbacks = [lang, ...(this.configService.configuration!.siteCultures.find((c) => c.cultureName === lang)!.fallbackCultureNames ?? [])];
    return forkJoin([
      observableWithFallbacks<ILocalizations>(cultureWithFallbacks.map((c) => this.httpClient.get<ILocalizations>(`/assets/translations/${c.toLowerCase()}.json?rnd=${Math.random()}`))),
      observableWithFallbacks<Partial<ILocalizations>>(cultureWithFallbacks.map((c) => this.resourceService.getTranslation(c)))
    ]).pipe(map((translations) => mergeDeep(translations[0], translations[1]) as ILocalizations));
  }
}
