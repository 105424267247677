import { ConfigurationService } from '@core-shared/services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

export function createJsonPatchPath(path: string): string {
  return '/' + path.replace(/\./g, '/');
}

export function getTextFromTranslatedCollection(text: { [key: string]: string } | undefined, configService: ConfigurationService, translate: TranslateService, useFallbackWhenAvailable: boolean = true): string {
  if (!text || !Object.keys(text).length) {
    return '';
  }
  const fallbacks = useFallbackWhenAvailable ? configService.configuration!.siteCultures.find((c) => translate.currentLang === c.cultureName)!.fallbackCultureNames : [];
  const fallBackTexts = fallbacks.map((f) => text[f]).filter((t) => t?.length);
  return text[translate.currentLang] ?? text[configService.configuration!.defaultSiteCultureName] ?? (fallBackTexts.length ? fallBackTexts[0] : '');
}

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);


export function getBrowserInfo(userAgent: string): BrowserInfo {
  const browserData = [
    {name: Browser.Edge, regex: /(?:Edge|Edg(?:iOS|A))\/(\S+)/},
    {name: Browser.Opera, regex: /(?:OPR|Opera(?: Mobi| Mini| Coast)?)\/(\S+)/},
    {name: Browser.Chrome, regex: /(?:Chrome|CriOS)\/(\S+)/},
    {name: Browser.Firefox, regex: /(?:Firefox|FxiOS)\/(\S+)/},
    {name: Browser.Safari, regex: /Version\/(\S+).*Safari/},
    {name: Browser.IE, regex: /(?:Trident\/7\.0|MSIE) (\S+);/},
    {name: Browser.Samsung, regex: /SamsungBrowser\/(\S+)/},
    {name: Browser.Huawei, regex: /HuaweiBrowser\/(\S+)/},
    {name: Browser.GoogleSearchApp, regex: /GSA\/(\S+)/}
  ];

  const osData = [
    {name: OperatingSystem.Windows, regex: /Windows NT (\d+\.\d+)/},
    {name: OperatingSystem.MacOS, regex: /Mac OS X (10(?:[._]\d+)+)/},
    {name: OperatingSystem.Android, regex: /Android (\S+)/},
    {name: OperatingSystem.Linux, regex: /Linux/},
    {name: OperatingSystem.iOS, regex: /(?:CPU|iPhone|iPad) OS (\S+) like Mac OS X/}
  ];

  let browser = Browser.Unknown;
  let browserVersion = '?';

  for (const {name, regex} of browserData) {
    const match = userAgent.match(regex);
    if (match) {
      browser = name;
      browserVersion = match[1];
      break;
    }
  }

  let os = OperatingSystem.Unknown;
  let osVersion = '?';

  for (const {name, regex} of osData) {
    const match = userAgent.match(regex);
    if (match) {
      os = name;
      osVersion = match[1]?.replace(/[._]/g, '.') || '?';
      break;
    }
  }

  return {
    browser,
    browserVersion,
    os,
    osVersion
  };
}

export function clearSecondsFromDate(date: string | DateTime): DateTime {
  const d = date instanceof DateTime ? date : DateTime.fromISO(date);
  return d.set({second: 0, millisecond: 0});
}


// TODO delete?
export function clearSwCache(): Promise<Promise<boolean>[][]> {
  return caches.keys().then((cacheKeys) =>
    Promise.all(
      cacheKeys
        .filter((cacheKey) => /^(ngsw).*/.test(cacheKey))
        .map((cacheKey) => caches
          .open(cacheKey)
          .then((cache) => cache.keys().then((requests) => requests.map((req) => cache.delete(req)))))
    )
  );
}

export function toCamelCase(str: string): string {
  return str.replace(/_[a-z]/g, (match) => match.charAt(1).toUpperCase());
}


export enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  Edge = 'Edge',
  IE = 'IE',
  Samsung = 'Samsung',
  Huawei = 'Huawei',
  GoogleSearchApp = 'GoogleSearchApp',
  Unknown = 'Unknown',
}

export enum OperatingSystem {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
  Android = 'Android',
  iOS = 'iOS',
  Unknown = 'Unknown',
}

export interface BrowserInfo {
  browser: Browser,
  browserVersion: string,
  os: OperatingSystem,
  osVersion: string
}
