/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuctionCatalogHierarchyDto } from '../models/auction-catalog-hierarchy-dto';
import { AuctionCatalogHierarchyDataDto } from '../models/auction-catalog-hierarchy-data-dto';
import { FindAuctionCatalogsResponseDto } from '../models/find-auction-catalogs-response-dto';
import { Operation } from '../models/operation';

@Injectable({
  providedIn: 'root',
})
export class GeneratedAuctionCatalogService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAuctionCatalogs
   */
  static readonly FindAuctionCatalogsPath = '/api/auction-catalogs';

  /**
   * Finds all auction-catalogs for a given auction.
   *
   * NOTE: Does not raise an error if the given auctionId is not found.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAuctionCatalogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAuctionCatalogs$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FindAuctionCatalogsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionCatalogService.FindAuctionCatalogsPath, 'get');
    if (params) {
      rb.query('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindAuctionCatalogsResponseDto>;
      })
    );
  }

  /**
   * Finds all auction-catalogs for a given auction.
   *
   * NOTE: Does not raise an error if the given auctionId is not found.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAuctionCatalogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAuctionCatalogs(params: {
    auctionId: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<FindAuctionCatalogsResponseDto> {

    return this.findAuctionCatalogs$Response(params,context).pipe(
      map((r: StrictHttpResponse<FindAuctionCatalogsResponseDto>) => r.body as FindAuctionCatalogsResponseDto)
    );
  }

  /**
   * Path part for operation createAuctionCatalogs
   */
  static readonly CreateAuctionCatalogsPath = '/api/auction-catalogs';

  /**
   * Creates a new auction-catalogs for a given auction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAuctionCatalogs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAuctionCatalogs$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body: AuctionCatalogHierarchyDataDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionCatalogHierarchyDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionCatalogService.CreateAuctionCatalogsPath, 'post');
    if (params) {
      rb.query('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionCatalogHierarchyDto>;
      })
    );
  }

  /**
   * Creates a new auction-catalogs for a given auction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAuctionCatalogs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAuctionCatalogs(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body: AuctionCatalogHierarchyDataDto
  },
  context?: HttpContext

): Observable<AuctionCatalogHierarchyDto> {

    return this.createAuctionCatalogs$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionCatalogHierarchyDto>) => r.body as AuctionCatalogHierarchyDto)
    );
  }

  /**
   * Path part for operation getAuctionCatalog
   */
  static readonly GetAuctionCatalogPath = '/api/auction-catalogs/{id}';

  /**
   * Gets an auction-catalog by its id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuctionCatalog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctionCatalog$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionCatalogHierarchyDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionCatalogService.GetAuctionCatalogPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionCatalogHierarchyDto>;
      })
    );
  }

  /**
   * Gets an auction-catalog by its id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuctionCatalog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctionCatalog(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<AuctionCatalogHierarchyDto> {

    return this.getAuctionCatalog$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionCatalogHierarchyDto>) => r.body as AuctionCatalogHierarchyDto)
    );
  }

  /**
   * Path part for operation deleteAuctionCatalog
   */
  static readonly DeleteAuctionCatalogPath = '/api/auction-catalogs/{id}';

  /**
   * Deletes an auction-catalogs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAuctionCatalog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteAuctionCatalog$Response(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionCatalogService.DeleteAuctionCatalogPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes an auction-catalogs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAuctionCatalog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteAuctionCatalog(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteAuctionCatalog$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchAuctionCatalog
   */
  static readonly PatchAuctionCatalogPath = '/api/auction-catalogs/{id}';

  /**
   * Patches an auction-catalogs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAuctionCatalog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAuctionCatalog$Response(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionCatalogHierarchyDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionCatalogService.PatchAuctionCatalogPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionCatalogHierarchyDto>;
      })
    );
  }

  /**
   * Patches an auction-catalogs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAuctionCatalog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAuctionCatalog(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<AuctionCatalogHierarchyDto> {

    return this.patchAuctionCatalog$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionCatalogHierarchyDto>) => r.body as AuctionCatalogHierarchyDto)
    );
  }

}
