/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MarkAuctionPaymentProcessStatus } from '../models/mark-auction-payment-process-status';

@Injectable({
  providedIn: 'root',
})
export class GeneratedAuctionPaymentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation markAuctionAsFullyPayed
   */
  static readonly MarkAuctionAsFullyPayedPath = '/api/auction-payments/{auctionId}';

  /**
   * Marks the given auction as fully payed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAuctionAsFullyPayed()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAuctionAsFullyPayed$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MarkAuctionPaymentProcessStatus>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionPaymentService.MarkAuctionAsFullyPayedPath, 'post');
    if (params) {
      rb.path('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarkAuctionPaymentProcessStatus>;
      })
    );
  }

  /**
   * Marks the given auction as fully payed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAuctionAsFullyPayed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAuctionAsFullyPayed(params: {
    auctionId: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<MarkAuctionPaymentProcessStatus> {

    return this.markAuctionAsFullyPayed$Response(params,context).pipe(
      map((r: StrictHttpResponse<MarkAuctionPaymentProcessStatus>) => r.body as MarkAuctionPaymentProcessStatus)
    );
  }

  /**
   * Path part for operation getMarkAuctionAsFullyPayedStatus
   */
  static readonly GetMarkAuctionAsFullyPayedStatusPath = '/api/auction-payments/{auctionId}/status';

  /**
   * Gets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarkAuctionAsFullyPayedStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarkAuctionAsFullyPayedStatus$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MarkAuctionPaymentProcessStatus>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionPaymentService.GetMarkAuctionAsFullyPayedStatusPath, 'get');
    if (params) {
      rb.path('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarkAuctionPaymentProcessStatus>;
      })
    );
  }

  /**
   * Gets.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarkAuctionAsFullyPayedStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarkAuctionAsFullyPayedStatus(params: {
    auctionId: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<MarkAuctionPaymentProcessStatus> {

    return this.getMarkAuctionAsFullyPayedStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<MarkAuctionPaymentProcessStatus>) => r.body as MarkAuctionPaymentProcessStatus)
    );
  }

}
