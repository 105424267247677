/* tslint:disable */
/* eslint-disable */
export enum MetricsResolution {
  Minutes = 'minutes',
  TenMinutes = 'ten_minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years'
}
