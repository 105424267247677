<swal [allowEscapeKey]="false"
      [allowOutsideClick]="false"
      [showCloseButton]="false"
      [showConfirmButton]="false"
      [width]="'35rem'"
      icon="error">
  <div *swalPortal="undefined" class="text-center">
    <h3 class="text-danger mb-4">
      {{lang === 'en' ? 'An error occurred.' : 'Ein Fehler ist aufgetreten.'}}
    </h3>
    <p *ngIf="lang === 'en'">An error has occurred. Please try again later.</p>
    <p *ngIf="lang === 'de'">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.</p>

    <button (click)="reload()" [disabled]="disabled" class="btn btn-secondary my-2">
      {{lang === 'en' ? 'Reload' : 'Neu laden'}}
    </button>
  </div>
</swal>
