import { Inject, Injectable, Optional } from '@angular/core';
import { from, Observable, tap } from 'rxjs';
import { EnvSettings } from '@core-shared/models/env-settings';

@Injectable({providedIn: 'root'})
export class EnvService {
  public settings?: EnvSettings;

  constructor(@Inject('envSettings') @Optional() envSettings?: EnvSettings) {
    this.settings = envSettings;
  }

  // TODO outsource to shared-package for frontend & backoffice (2 implementations = overhead & inconsistency) & decide whether to use the environment.ts OR a settings-property within this class
  public getEnvSettings(): Observable<EnvSettings> {
    return from(fetchEnvVariables())
      .pipe(tap((settings) => this.settings = settings));
  }
}

// TODO handle error-case?
export async function fetchEnvVariables(): Promise<EnvSettings> {
  return fetch(`assets/env.json?rnd=${Math.random()}`)
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      else if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError('fetched env.json received as' + contentType);
      }
      return response.json();
    });
}
