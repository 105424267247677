/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TickerUiSettingsDto } from '../models/ticker-ui-settings-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedTickerUiSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTickerUiSettings
   */
  static readonly GetTickerUiSettingsPath = '/api/ticker-ui-settings';

  /**
   * Gets the current ticker ui settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTickerUiSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTickerUiSettings$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TickerUiSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedTickerUiSettingsService.GetTickerUiSettingsPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TickerUiSettingsDto>;
      })
    );
  }

  /**
   * Gets the current ticker ui settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTickerUiSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTickerUiSettings(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<TickerUiSettingsDto> {

    return this.getTickerUiSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<TickerUiSettingsDto>) => r.body as TickerUiSettingsDto)
    );
  }

  /**
   * Path part for operation setTickerUiSettings
   */
  static readonly SetTickerUiSettingsPath = '/api/ticker-ui-settings';

  /**
   * Sets the current ticker ui settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setTickerUiSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setTickerUiSettings$Response(params: {
    'X-API-Version'?: string;
    body: TickerUiSettingsDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedTickerUiSettingsService.SetTickerUiSettingsPath, 'put');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sets the current ticker ui settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setTickerUiSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setTickerUiSettings(params: {
    'X-API-Version'?: string;
    body: TickerUiSettingsDto
  },
  context?: HttpContext

): Observable<void> {

    return this.setTickerUiSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
