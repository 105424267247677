/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateAcceptedCreditRequestRequest } from '../models/create-accepted-credit-request-request';
import { CreditRequestDto } from '../models/credit-request-dto';
import { FindCreditRequestsResponseDto } from '../models/find-credit-requests-response-dto';
import { Operation } from '../models/operation';

@Injectable({
  providedIn: 'root',
})
export class GeneratedCreditRequestService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findCreditRequests
   */
  static readonly FindCreditRequestsPath = '/api/credit-requests';

  /**
   * Finds open credit requests.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCreditRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCreditRequests$Response(params: {
    user_id?: number;
    pending_only: boolean;
    '$sortby': string;
    '$sortdir': string;
    '$page': number;
    '$maxpagesize': number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FindCreditRequestsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedCreditRequestService.FindCreditRequestsPath, 'get');
    if (params) {
      rb.query('user_id', params.user_id, {});
      rb.query('pending_only', params.pending_only, {});
      rb.query('$sortby', params['$sortby'], {});
      rb.query('$sortdir', params['$sortdir'], {});
      rb.query('$page', params['$page'], {});
      rb.query('$maxpagesize', params['$maxpagesize'], {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindCreditRequestsResponseDto>;
      })
    );
  }

  /**
   * Finds open credit requests.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findCreditRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCreditRequests(params: {
    user_id?: number;
    pending_only: boolean;
    '$sortby': string;
    '$sortdir': string;
    '$page': number;
    '$maxpagesize': number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<FindCreditRequestsResponseDto> {

    return this.findCreditRequests$Response(params,context).pipe(
      map((r: StrictHttpResponse<FindCreditRequestsResponseDto>) => r.body as FindCreditRequestsResponseDto)
    );
  }

  /**
   * Path part for operation createAcceptedCreditRequest
   */
  static readonly CreateAcceptedCreditRequestPath = '/api/credit-requests';

  /**
   * Creates an immediately "accepted" credit request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAcceptedCreditRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAcceptedCreditRequest$Response(params: {
    'X-API-Version'?: string;
    body: CreateAcceptedCreditRequestRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CreditRequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedCreditRequestService.CreateAcceptedCreditRequestPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRequestDto>;
      })
    );
  }

  /**
   * Creates an immediately "accepted" credit request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAcceptedCreditRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAcceptedCreditRequest(params: {
    'X-API-Version'?: string;
    body: CreateAcceptedCreditRequestRequest
  },
  context?: HttpContext

): Observable<CreditRequestDto> {

    return this.createAcceptedCreditRequest$Response(params,context).pipe(
      map((r: StrictHttpResponse<CreditRequestDto>) => r.body as CreditRequestDto)
    );
  }

  /**
   * Path part for operation patchCreditRequest
   */
  static readonly PatchCreditRequestPath = '/api/credit-requests/{id}';

  /**
   * Patches an open credit request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCreditRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchCreditRequest$Response(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CreditRequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedCreditRequestService.PatchCreditRequestPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRequestDto>;
      })
    );
  }

  /**
   * Patches an open credit request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCreditRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchCreditRequest(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<CreditRequestDto> {

    return this.patchCreditRequest$Response(params,context).pipe(
      map((r: StrictHttpResponse<CreditRequestDto>) => r.body as CreditRequestDto)
    );
  }

}
