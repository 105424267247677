/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SecondFactorRequestDto } from '../models/second-factor-request-dto';
import { SecondFactorSignInRequestDto } from '../models/second-factor-sign-in-request-dto';
import { SessionInfoDto } from '../models/session-info-dto';
import { SignInRequestDto } from '../models/sign-in-request-dto';
import { SignInResponseDto } from '../models/sign-in-response-dto';
import { SignInSixbidRequestDto } from '../models/sign-in-sixbid-request-dto';
import { SignOutRequestDto } from '../models/sign-out-request-dto';
import { TwoFactorRecoveryCodeSignInRequestDto } from '../models/two-factor-recovery-code-sign-in-request-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedSessionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSessionInfo
   */
  static readonly GetSessionInfoPath = '/api/session';

  /**
   * Gets information about the current session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSessionInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionInfo$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SessionInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.GetSessionInfoPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SessionInfoDto>;
      })
    );
  }

  /**
   * Gets information about the current session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSessionInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionInfo(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<SessionInfoDto> {

    return this.getSessionInfo$Response(params,context).pipe(
      map((r: StrictHttpResponse<SessionInfoDto>) => r.body as SessionInfoDto)
    );
  }

  /**
   * Path part for operation signIn
   */
  static readonly SignInPath = '/api/session';

  /**
   * Sign in via username and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  signIn$Response(params: {
    'X-API-Version'?: string;
    body: SignInRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SignInResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.SignInPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponseDto>;
      })
    );
  }

  /**
   * Sign in via username and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  signIn(params: {
    'X-API-Version'?: string;
    body: SignInRequestDto
  },
  context?: HttpContext

): Observable<SignInResponseDto> {

    return this.signIn$Response(params,context).pipe(
      map((r: StrictHttpResponse<SignInResponseDto>) => r.body as SignInResponseDto)
    );
  }

  /**
   * Path part for operation signOut
   */
  static readonly SignOutPath = '/api/session';

  /**
   * Sign out.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signOut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  signOut$Response(params?: {
    'X-API-Version'?: string;
    body?: SignOutRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.SignOutPath, 'delete');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sign out.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signOut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  signOut(params?: {
    'X-API-Version'?: string;
    body?: SignOutRequestDto
  },
  context?: HttpContext

): Observable<void> {

    return this.signOut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation twoFactorSignIn
   */
  static readonly TwoFactorSignInPath = '/api/session/mfa-token';

  /**
   * Complete the previous sign-in via MFA token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `twoFactorSignIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  twoFactorSignIn$Response(params: {
    'X-API-Version'?: string;
    body: SecondFactorSignInRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SignInResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.TwoFactorSignInPath, 'put');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponseDto>;
      })
    );
  }

  /**
   * Complete the previous sign-in via MFA token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `twoFactorSignIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  twoFactorSignIn(params: {
    'X-API-Version'?: string;
    body: SecondFactorSignInRequestDto
  },
  context?: HttpContext

): Observable<SignInResponseDto> {

    return this.twoFactorSignIn$Response(params,context).pipe(
      map((r: StrictHttpResponse<SignInResponseDto>) => r.body as SignInResponseDto)
    );
  }

  /**
   * Path part for operation requestTwoFactorCode
   */
  static readonly RequestTwoFactorCodePath = '/api/session/mfa-token';

  /**
   * Requests a new MFA token via mail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestTwoFactorCode()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestTwoFactorCode$Response(params: {
    'X-API-Version'?: string;
    body: SecondFactorRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.RequestTwoFactorCodePath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Requests a new MFA token via mail.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestTwoFactorCode$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestTwoFactorCode(params: {
    'X-API-Version'?: string;
    body: SecondFactorRequestDto
  },
  context?: HttpContext

): Observable<void> {

    return this.requestTwoFactorCode$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation twoFactorRecoveryCodeSignIn
   */
  static readonly TwoFactorRecoveryCodeSignInPath = '/api/session/mfa-recovery-code';

  /**
   * Sign in via a one-time password (recovery code).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `twoFactorRecoveryCodeSignIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  twoFactorRecoveryCodeSignIn$Response(params: {
    'X-API-Version'?: string;
    body: TwoFactorRecoveryCodeSignInRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SignInResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.TwoFactorRecoveryCodeSignInPath, 'put');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponseDto>;
      })
    );
  }

  /**
   * Sign in via a one-time password (recovery code).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `twoFactorRecoveryCodeSignIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  twoFactorRecoveryCodeSignIn(params: {
    'X-API-Version'?: string;
    body: TwoFactorRecoveryCodeSignInRequestDto
  },
  context?: HttpContext

): Observable<SignInResponseDto> {

    return this.twoFactorRecoveryCodeSignIn$Response(params,context).pipe(
      map((r: StrictHttpResponse<SignInResponseDto>) => r.body as SignInResponseDto)
    );
  }

  /**
   * Path part for operation signInExternal
   */
  static readonly SignInExternalPath = '/api/session/external';

  /**
   * Sign in via an external authentication provider.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signInExternal()` instead.
   *
   * This method doesn't expect any request body.
   */
  signInExternal$Response(params: {
    'X-API-Version'?: string;

    /**
     * Authentication type and bearer token
     */
    Authorization: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SignInResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.SignInExternalPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.header('Authorization', params.Authorization, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponseDto>;
      })
    );
  }

  /**
   * Sign in via an external authentication provider.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signInExternal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signInExternal(params: {
    'X-API-Version'?: string;

    /**
     * Authentication type and bearer token
     */
    Authorization: string;
  },
  context?: HttpContext

): Observable<SignInResponseDto> {

    return this.signInExternal$Response(params,context).pipe(
      map((r: StrictHttpResponse<SignInResponseDto>) => r.body as SignInResponseDto)
    );
  }

  /**
   * Path part for operation signInSixbid
   */
  static readonly SignInSixbidPath = '/api/session/sixbid';

  /**
   * Sign in via a sixbid token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signInSixbid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  signInSixbid$Response(params: {
    'X-API-Version'?: string;
    body: SignInSixbidRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SignInResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.SignInSixbidPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponseDto>;
      })
    );
  }

  /**
   * Sign in via a sixbid token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signInSixbid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  signInSixbid(params: {
    'X-API-Version'?: string;
    body: SignInSixbidRequestDto
  },
  context?: HttpContext

): Observable<SignInResponseDto> {

    return this.signInSixbid$Response(params,context).pipe(
      map((r: StrictHttpResponse<SignInResponseDto>) => r.body as SignInResponseDto)
    );
  }

  /**
   * Path part for operation issueTickerAuthToken
   */
  static readonly IssueTickerAuthTokenPath = '/api/session/ticker_auth_token';

  /**
   * Issues a new authentication token for the ticker hub. Previous tokens will be revoked.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issueTickerAuthToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  issueTickerAuthToken$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedSessionService.IssueTickerAuthTokenPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Issues a new authentication token for the ticker hub. Previous tokens will be revoked.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issueTickerAuthToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issueTickerAuthToken(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.issueTickerAuthToken$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
