/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MaterialDto } from '../models/material-dto';
import { MaterialDataDto } from '../models/material-data-dto';
import { Operation } from '../models/operation';

@Injectable({
  providedIn: 'root',
})
export class GeneratedMaterialService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMaterials
   */
  static readonly GetMaterialsPath = '/api/materials';

  /**
   * Gets all materials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterials()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterials$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MaterialDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedMaterialService.GetMaterialsPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MaterialDto>>;
      })
    );
  }

  /**
   * Gets all materials.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterials(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<Array<MaterialDto>> {

    return this.getMaterials$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MaterialDto>>) => r.body as Array<MaterialDto>)
    );
  }

  /**
   * Path part for operation createMaterial
   */
  static readonly CreateMaterialPath = '/api/materials';

  /**
   * Creates a material.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMaterial$Response(params: {
    'X-API-Version'?: string;
    body: MaterialDataDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MaterialDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedMaterialService.CreateMaterialPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaterialDto>;
      })
    );
  }

  /**
   * Creates a material.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMaterial(params: {
    'X-API-Version'?: string;
    body: MaterialDataDto
  },
  context?: HttpContext

): Observable<MaterialDto> {

    return this.createMaterial$Response(params,context).pipe(
      map((r: StrictHttpResponse<MaterialDto>) => r.body as MaterialDto)
    );
  }

  /**
   * Path part for operation deleteMaterial
   */
  static readonly DeleteMaterialPath = '/api/materials/{id}';

  /**
   * Deletes a material.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMaterial$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedMaterialService.DeleteMaterialPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a material.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMaterial(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteMaterial$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchMaterial
   */
  static readonly PatchMaterialPath = '/api/materials/{id}';

  /**
   * Patches a material.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchMaterial$Response(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MaterialDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedMaterialService.PatchMaterialPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaterialDto>;
      })
    );
  }

  /**
   * Patches a material.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchMaterial(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<MaterialDto> {

    return this.patchMaterial$Response(params,context).pipe(
      map((r: StrictHttpResponse<MaterialDto>) => r.body as MaterialDto)
    );
  }

}
