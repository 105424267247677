/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class GeneratedCsvExportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation exportPrebidsCsv
   */
  static readonly ExportPrebidsCsvPath = '/api/csv-export/prebids';

  /**
   * Exports a list of prebids for a given auction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPrebidsCsv()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportPrebidsCsv$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body?: {
'columns'?: Array<string>;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedCsvExportService.ExportPrebidsCsvPath, 'post');
    if (params) {
      rb.query('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/csv',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Exports a list of prebids for a given auction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportPrebidsCsv$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportPrebidsCsv(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body?: {
'columns'?: Array<string>;
}
  },
  context?: HttpContext

): Observable<Blob> {

    return this.exportPrebidsCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportAcceptedBidsCsv
   */
  static readonly ExportAcceptedBidsCsvPath = '/api/csv-export/accepted-bids';

  /**
   * Exports a list of accepted bids for a given auction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportAcceptedBidsCsv()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportAcceptedBidsCsv$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body?: {
'columns'?: Array<string>;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedCsvExportService.ExportAcceptedBidsCsvPath, 'post');
    if (params) {
      rb.query('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/csv',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Exports a list of accepted bids for a given auction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportAcceptedBidsCsv$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportAcceptedBidsCsv(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body?: {
'columns'?: Array<string>;
}
  },
  context?: HttpContext

): Observable<Blob> {

    return this.exportAcceptedBidsCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
