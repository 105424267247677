/* tslint:disable */
/* eslint-disable */
export enum CreateAcceptedBidConflictType {
  LotNotFound = 'lot_not_found',
  LotIsWithdrawn = 'lot_is_withdrawn',
  LotIsAlreadySold = 'lot_is_already_sold',
  LotNotAvailable = 'lot_not_available',
  BidderNotFound = 'bidder_not_found',
  BidderNotInAuction = 'bidder_not_in_auction'
}
