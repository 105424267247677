import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ResourceService } from '@core-shared/services/resource.service';
import { CustomMissingTranslationHandler } from '@core-shared/misc/custom-missing-translation-handler';
import { ToastrModule } from 'ngx-toastr';


import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/cs';

import '@angular/common/locales/global/es';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/hu';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/sk';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpInterceptorService } from '@core-shared/services/http-interceptor.service';
import { BootstrapErrorComponent } from './components/bootstrap-error/bootstrap-error.component';
import { CustomTranslateLoaderService } from '@core-shared/services/custom-translate-http-loader.service';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { UpdateService } from '@core-shared/services/update.service';
import { ConfigurationService } from '@core-shared/services/configuration.service';
import { NaclKeyEncryption } from '@core-shared/services/nacl-key-encryption';
import { KEY_ENCRYPTION } from '@core-shared/services/key-encryption';
import { BINARY_ENCRYPTION } from '@core-shared/services/binary-encryption';
import { NaclBinaryEncryption } from '@core-shared/services/nacl-binary-encryption';
import { SwalModule } from '@sweetalert2/ngx-sweetalert2/lib/sweetalert2-loader.service';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    BootstrapErrorComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
        deps: [ResourceService]
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient, resourceService: ResourceService, configService: ConfigurationService) => new CustomTranslateLoaderService(http, resourceService, configService),
        deps: [HttpClient, ResourceService, ConfigurationService]
      }
    }),
    SweetAlert2Module.forRoot({
      provideSwal,
      dismissOnDestroy: false
    }),
    LoadingBarHttpClientModule,
    OAuthModule.forRoot()
    /* ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }) */
  ],
  providers: [
    {provide: LOADING_BAR_CONFIG, useValue: {latencyThreshold: 200}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({showDialog: false})
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    UpdateService,
    {provide: KEY_ENCRYPTION, useClass: NaclKeyEncryption},
    {provide: BINARY_ENCRYPTION, useClass: NaclBinaryEncryption}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


export function provideSwal(): Promise<SwalModule> {
  // tslint:disable-next-line:no-shadowed-variable
  return import('sweetalert2').then(({default: Swal}) => Swal.mixin({
    showCloseButton: true,
    buttonsStyling: false,
    customClass: {confirmButton: 'btn btn-primary btn-fill', cancelButton: 'btn btn-outline-secondary ms-3'}
  }));
}
