import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ILocalizations } from '@core-shared/models/localization-interfaces';
import { Settings } from 'luxon';

@Injectable({providedIn: 'root'})
export class TranslationService {

  private readonly translation$: BehaviorSubject<ILocalizations | undefined> = new BehaviorSubject<ILocalizations | undefined>(undefined);

  constructor(private translate: TranslateService) {
  }

  // gets called after bootstrapping finished
  public init(localizations: ILocalizations): void {
    this.translation$.next(localizations);
    Settings.defaultLocale = this.translate.currentLang;
  }


  public get translation(): ILocalizations {
    return this.translation$.value!;
  }


}
