/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddBinaryReferenceRequestDto } from '../models/add-binary-reference-request-dto';
import { BinaryUploadInfoDto } from '../models/binary-upload-info-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedBinaryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addBinaryReference
   */
  static readonly AddBinaryReferencePath = '/api/binaries/sha1/{sha1}/refs';

  /**
   * Adds a reference to an existing binary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBinaryReference()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBinaryReference$Response(params: {
    sha1: string;
    'X-API-Version'?: string;
    body: AddBinaryReferenceRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BinaryUploadInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedBinaryService.AddBinaryReferencePath, 'post');
    if (params) {
      rb.path('sha1', params.sha1, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BinaryUploadInfoDto>;
      })
    );
  }

  /**
   * Adds a reference to an existing binary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBinaryReference$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBinaryReference(params: {
    sha1: string;
    'X-API-Version'?: string;
    body: AddBinaryReferenceRequestDto
  },
  context?: HttpContext

): Observable<BinaryUploadInfoDto> {

    return this.addBinaryReference$Response(params,context).pipe(
      map((r: StrictHttpResponse<BinaryUploadInfoDto>) => r.body as BinaryUploadInfoDto)
    );
  }

  /**
   * Path part for operation uploadBinaryToLot
   */
  static readonly UploadBinaryToLotPath = '/api/binaries/lots/{id}';

  /**
   * Uploads a new binary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadBinaryToLot()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadBinaryToLot$Response(params: {
    lotId: number;
    id: string;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BinaryUploadInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedBinaryService.UploadBinaryToLotPath, 'post');
    if (params) {
      rb.path('lotId', params.lotId, {});
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BinaryUploadInfoDto>;
      })
    );
  }

  /**
   * Uploads a new binary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadBinaryToLot$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadBinaryToLot(params: {
    lotId: number;
    id: string;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<BinaryUploadInfoDto> {

    return this.uploadBinaryToLot$Response(params,context).pipe(
      map((r: StrictHttpResponse<BinaryUploadInfoDto>) => r.body as BinaryUploadInfoDto)
    );
  }

  /**
   * Path part for operation uploadBinaryByNamingConvention
   */
  static readonly UploadBinaryByNamingConventionPath = '/api/binaries/auctions/{auctionId}';

  /**
   * Uploads a new binary by naming convention.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadBinaryByNamingConvention()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadBinaryByNamingConvention$Response(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BinaryUploadInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedBinaryService.UploadBinaryByNamingConventionPath, 'post');
    if (params) {
      rb.path('auctionId', params.auctionId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BinaryUploadInfoDto>;
      })
    );
  }

  /**
   * Uploads a new binary by naming convention.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadBinaryByNamingConvention$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadBinaryByNamingConvention(params: {
    auctionId: number;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<BinaryUploadInfoDto> {

    return this.uploadBinaryByNamingConvention$Response(params,context).pipe(
      map((r: StrictHttpResponse<BinaryUploadInfoDto>) => r.body as BinaryUploadInfoDto)
    );
  }

}
