/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BackupFileDto } from '../models/backup-file-dto';
import { CustomLocalizationTarget } from '../models/custom-localization-target';
import { LocalizationRestoreRequestDto } from '../models/localization-restore-request-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedLocalizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation backupLocalizations
   */
  static readonly BackupLocalizationsPath = '/api/localizations/{type}/backup';

  /**
   * Backups custom localizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `backupLocalizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  backupLocalizations$Response(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BackupFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedLocalizationService.BackupLocalizationsPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;auex-usage&#x3D;resbu',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackupFileDto>;
      })
    );
  }

  /**
   * Backups custom localizations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `backupLocalizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  backupLocalizations(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<BackupFileDto> {

    return this.backupLocalizations$Response(params,context).pipe(
      map((r: StrictHttpResponse<BackupFileDto>) => r.body as BackupFileDto)
    );
  }

  /**
   * Path part for operation restoreLocalizations
   */
  static readonly RestoreLocalizationsPath = '/api/localizations/{type}/backup';

  /**
   * Restores a backup of custom localizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreLocalizations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  restoreLocalizations$Response(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
    body: LocalizationRestoreRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedLocalizationService.RestoreLocalizationsPath, 'put');
    if (params) {
      rb.path('type', params.type, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Restores a backup of custom localizations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreLocalizations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  restoreLocalizations(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
    body: LocalizationRestoreRequestDto
  },
  context?: HttpContext

): Observable<void> {

    return this.restoreLocalizations$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation restoreLocalizationFile
   */
  static readonly RestoreLocalizationFilePath = '/api/localizations/{type}/backup/file';

  /**
   * Restores a backup-file of custom localizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreLocalizationFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  restoreLocalizationFile$Response(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
'cleanBeforeRestore'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedLocalizationService.RestoreLocalizationFilePath, 'put');
    if (params) {
      rb.path('type', params.type, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Restores a backup-file of custom localizations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreLocalizationFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  restoreLocalizationFile(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
'cleanBeforeRestore'?: boolean;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.restoreLocalizationFile$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLocalizations
   */
  static readonly GetLocalizationsPath = '/api/localizations/{type}';

  /**
   * Gets the custom localizations by their specified type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocalizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocalizations$Response(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
[key: string]: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedLocalizationService.GetLocalizationsPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: any;
        }>;
      })
    );
  }

  /**
   * Gets the custom localizations by their specified type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocalizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocalizations(params: {
    type: CustomLocalizationTarget;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<{
[key: string]: any;
}> {

    return this.getLocalizations$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: any;
}>) => r.body as {
[key: string]: any;
})
    );
  }

}
