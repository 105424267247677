/* tslint:disable */
/* eslint-disable */
export enum UserVerificationState {
  NotVerified = 'not_verified',
  Requested = 'requested',
  Started = 'started',
  InProgress = 'in_progress',
  ManualVerificationRequired = 'manual_verification_required',
  Verified = 'verified',
  Declined = 'declined',
  Canceled = 'canceled',
  Error = 'error'
}
