import { Inject, Injectable, TemplateRef } from '@angular/core';
import { GeneratedConfigService } from '@core-shared/generated/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs/operators';
import { ConfigDto } from '@core-shared/generated/models/config-dto';

@Injectable({providedIn: 'root'})
export class ConfigurationService {

  public headerTemplateRef$: Subject<TemplateRef<unknown> | undefined> = new Subject<TemplateRef<unknown> | undefined>();   // used for displaying alternative data in hedaer instead of "Dashboard"-text

  public configuration?: ConfigDto; // todo replace with get method - throw error when null
  public readonly $configuration: BehaviorSubject<ConfigDto | null> = new BehaviorSubject<ConfigDto | null>(null); // todo remove - shouldnt be necessary when implemented get method for config


  constructor(@Inject(DOCUMENT) private document: Document, private generatedService: GeneratedConfigService) {
  }

  public getConfig(): Observable<ConfigDto> {
    return this.generatedService.getConfig()
      .pipe(tap((config) => {
        this.configuration = config;
        this.$configuration.next(config);
        this.setFavIcons();
        this.setThemeColor();
      }));
  }

  public getEncryptionKeyExportSalt(): Observable<string> {
    return this.generatedService.getEncryptionKeyExportSalt();
  }

  public storeDocumentEncryptionPublicKey(publicKey: string): Observable<void> {
    return this.generatedService.storeDocumentEncryptionPublicKey({body: '"' + publicKey + '"'}).pipe(
      tap(() => {
        this.configuration!.documentEncryptionPublicKey = publicKey;
        this.$configuration.next(this.configuration!);
      })
    );
  }

  private setThemeColor(): void {
    const head = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;
    const metaTag = document.createElement('meta') as HTMLMetaElement;
    metaTag.name = 'theme-color';
    metaTag.content = this.configuration!.themeColor;
    head.appendChild(metaTag);
  }

  private setFavIcons(): void {
    ['favIconMobile', 'favIconDesktop'].forEach((id) => {
      const link = this.document.getElementById(id);
      if (link) {
        (link as HTMLLinkElement).href = this.$configuration.value!.favIcon;
      }
    });
  }

}
