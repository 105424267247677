/* tslint:disable */
/* eslint-disable */
export enum ImportLotsCsvRowConflictType {
  InvalidLotNumber = 'invalid_lot_number',
  MissingDefaultCategory1 = 'missing_default_category1',
  MissingDefaultCategory2 = 'missing_default_category2',
  MissingDefaultCategory3 = 'missing_default_category3',
  MissingDefaultCategory4 = 'missing_default_category4',
  InterruptedCategorySequence = 'interrupted_category_sequence',
  InvalidPrice = 'invalid_price',
  InvalidSellingPrice = 'invalid_selling_price',
  MissingDefaultDescription = 'missing_default_description',
  MissingDefaultShortDescription = 'missing_default_short_description',
  MissingDefaultDetails = 'missing_default_details',
  InvalidStartingPrice = 'invalid_starting_price',
  InvalidVat = 'invalid_vat',
  DuplicateLotNumber = 'duplicate_lot_number',
  NoMatchingAuctionCatalogPart = 'no_matching_auction_catalog_part',
  CannotChangePriceForNonAvailableLot = 'cannot_change_price_for_non_available_lot',
  CannotChangeSellingPriceForSoldLot = 'cannot_change_selling_price_for_sold_lot',
  InvalidConsignerEmail = 'invalid_consigner_email',
  ConsignerNotFound = 'consigner_not_found'
}
