import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environment';
import { EnvSettings } from '@core-shared/models/env-settings';
import { fetchEnvVariables } from '@core-shared/services/env.service';
import { initSentry } from '@core-shared/misc/sentry';

if (environment.production) {
  enableProdMode();
}

function bootstrap(): void {
  const start = (settings: EnvSettings) => {
    initSentry(settings);
    platformBrowserDynamic([{provide: 'envSettings', useValue: settings}])
      .bootstrapModule(AppModule, {preserveWhitespaces: true})
      .catch((err) => console.log(err));
  };

  // TODO check if this works or if its even needed - I think we should always fetch the env-variables from the env-file
  const assignBrowserWindowEnv = () => {
    const env: any = {};
    const browserWindowEnv = (window as any)['_env'] ?? {};
    for (const key in browserWindowEnv) {
      if (browserWindowEnv.hasOwnProperty(key)) {
        env[key] = browserWindowEnv[key];
      }
    }
    return env;
  };

  if (window && (window as any)['_env']) {
    start(assignBrowserWindowEnv());
  }
  else {
    fetchEnvVariables().then((settings) => start(settings));
  }
}


if (document.readyState === 'complete') {
  bootstrap();
}
else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

