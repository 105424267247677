import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneratedLocalizationService } from '@core-shared/generated/services/generated-localization.service';
import { HttpClient } from '@angular/common/http';
import { CustomLocalizationTarget } from '@core-shared/generated/models/custom-localization-target';

@Injectable({providedIn: 'root'})
export class ResourceService {

  constructor(private http: HttpClient, private generatedService: GeneratedLocalizationService) {
  }

  // TODO move to custom-translate-http-loader?
  public getTranslation(culture?: string, type: CustomLocalizationTarget = CustomLocalizationTarget.Backoffice): Observable<any> {
    const path = GeneratedLocalizationService.GetLocalizationsPath.split('/');
    const typeKey = path[path.length - 1];
    return this.http.get(GeneratedLocalizationService.GetLocalizationsPath.replace(typeKey, type), {headers: {'Accept-Language': culture ?? 'en-us'}});
  }

  public getMissingTranslation(type: CustomLocalizationTarget = CustomLocalizationTarget.Backoffice): Observable<any> {
    return new Observable<any>();
    // return this.apiResourceMissingGet({type: type});
  }

  public addMissingTranslationKey(culture: string, key: string): Observable<void> {
    return new Observable<any>();
    // return this.apiResourceMissingPost({key: key, cultureString: culture});
  }

  public removeMissingTranslation(key?: string): Observable<void> {
    return new Observable<any>();
    // return this.apiResourceMissingDelete({key: key});
  }

  public restoreTranslations(data: { key: string, value: any }[], type: CustomLocalizationTarget = CustomLocalizationTarget.Backoffice): Observable<void> {
    return new Observable<any>();
    // return this.apiResourceRestorePost({body: data, type: type});
  }

  public mergeMissingWithExisting(type: CustomLocalizationTarget): Observable<void> {
    return new Observable<any>();
    // return this.apiResourceMergeMissingWithExistingPost({type: type});
  }

  public updatePartially(data: any, type: CustomLocalizationTarget): Observable<void> {
    return new Observable<any>();
    // return this.apiResourcePartialRestorePost({type: type, body: data});
  }

}
