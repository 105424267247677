import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, switchMap, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, map } from 'rxjs/operators';
import { SessionService } from '@core-shared/services/session.service';
import { UserProfileService } from '@core-shared/services/user-profile.service';
import { ConfigurationService } from '@core-shared/services/configuration.service';
import { EnvService } from '@core-shared/services/env.service';
import { TranslationService } from '@core-shared/services/translation.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({providedIn: 'root'})
export class BootstrapGuard  {

  public readonly bootstrappingState: BehaviorSubject<'loading' | 'error' | 'success'> = new BehaviorSubject<'loading' | 'error' | 'success'>('loading');

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private envService: EnvService,
              private sessionService: SessionService,
              private userProfileService: UserProfileService,
              private configService: ConfigurationService,
              private router: Router,
              private route: ActivatedRoute,
              private translationService: TranslationService,
              private translate: TranslateService) {
    this.bootstrap();
  }

  private bootstrap(): void {
    // get config + session-info
    forkJoin([
      this.configService.getConfig(),
      this.sessionService.getSessionInfoWithUserProfile().pipe(catchError(() => of(undefined)))
    ]).pipe(switchMap((result) => {
      const config = result[0]!;

      // found session-info? get userprofile and use the saved language
      if (result[1] && config.siteCultures.find((c) => c.cultureName === result[1]!.userProfile!.settings.cultureName)) {
        return this.translate.use(result[1].userProfile!.settings.cultureName);
      }
      // no session-info found? try to get lang from localstorage, otherwise from browser
      else {
        const langSelectedByUser = isPlatformBrowser(this.platformId) ? localStorage.getItem('auex_lang') : undefined;
        const navLang = this.translate.getBrowserCultureLang();

        let langToLoad;
        // case 1: user manually has set his language previously
        if (langSelectedByUser?.length && config.siteCultures.find((c) => c.cultureName === langSelectedByUser)) {
          langToLoad = langSelectedByUser;
        }
        // case 2: exact matching of available cultures && browser-culture
        else if (navLang && config.siteCultures.some((culture) => culture.cultureName === navLang)) {
          langToLoad = navLang;
        }
        // case 3: partial matching of existing cultures && browser-culture
        else {
          langToLoad = config.siteCultures.find((culture) => culture.cultureName.split('-')[0] === navLang?.split('-')[0])?.cultureName;
        }

        return this.translate.use(langToLoad ?? config.defaultSiteCultureName);
      }
    }))
      .subscribe({
        next: (res) => {
          this.translationService.init(res);
          this.bootstrappingState.next('success');
        },
        error: () => this.bootstrappingState.next('error')
      });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.bootstrappingState
      .pipe(filter((s) => s !== 'loading'))
      .pipe(take(1))
      .pipe(map((s) => s === 'success'));
  }

}
