/* tslint:disable */
/* eslint-disable */
export enum ImportLotsCsvConflictType {
  None = 'none',
  MissingHeader = 'missing_header',
  InvalidHeader = 'invalid_header',
  UnexpectedColumnNames = 'unexpected_column_names',
  DuplicateColumnNames = 'duplicate_column_names',
  UnexpectedLanguage = 'unexpected_language',
  MissingRequiredColumns = 'missing_required_columns',
  MissingDefaultLanguageColumns = 'missing_default_language_columns',
  NoRows = 'no_rows',
  CannotWithdrawSoldLots = 'cannot_withdraw_sold_lots',
  CannotWithdrawLotsWithBids = 'cannot_withdraw_lots_with_bids'
}
