import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { EncryptionKeyPair } from '@core-shared/models/encryption-key-pair';

export const BINARY_ENCRYPTION = new InjectionToken<BinaryEncryption>('BINARY_ENCRYPTION');

export interface BinaryEncryption {
  createKeyPair(): EncryptionKeyPair,

  decrypt(encryptedData: Uint8Array, encryptedKey: string, nonce: string, key: Uint8Array): string,

  encrypt(data: File, key: Uint8Array): Observable<{ nonce: string, encryptedData: Uint8Array, encryptedKeyWithPublicKey: string, sha1: string }>
}
