/* tslint:disable */
/* eslint-disable */
export enum CreatePrebidConflictType {
  UserNotFound = 'user_not_found',
  LotNotFound = 'lot_not_found',
  PrebidWithHigherOrEqualAmountExists = 'prebid_with_higher_or_equal_amount_exists',
  LotNotAvailable = 'lot_not_available',
  AuctionIsArchived = 'auction_is_archived',
  BidAmountLowerThanStartingBid = 'bid_amount_lower_than_starting_bid',
  BidAmountMustNotContainDecimalPlaces = 'bid_amount_must_not_contain_decimal_places',
  BidAmountNotAlignedToBidIncrements = 'bid_amount_not_aligned_to_bid_increments'
}
