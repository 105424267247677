import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@core-shared/services/session.service';
import { BootstrapGuard } from '../../dashboard/bootstrap-guard.service';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotLoggedInAuthGuardService implements CanActivate {

  constructor(private sessionService: SessionService,
              private router: Router,
              private bootstrapGuard: BootstrapGuard) {
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await firstValueFrom(this.bootstrapGuard.bootstrappingState.pipe(filter((loadingState) => loadingState === 'success')));
    if (this.sessionService.$sessionInfo.value) {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
    return true;
  }


}
