import { KeyEncryption } from '@core-shared/services/key-encryption';
import { randomBytes, secretbox } from 'tweetnacl';
import { algo, PBKDF2 } from 'crypto-js';

export class NaclKeyEncryption implements KeyEncryption {
  // constructor(crypto: Crypto) {
  //   // crypto.subtle.deriveKey({},)
  // }

  private createDerivedKey(password: string, salt: string): Uint8Array {
    const key = PBKDF2(password, salt, {keySize: 8, hasher: algo.SHA256, iterations: 100000});
    // Copied from here: https://stackoverflow.com/questions/68557340/how-to-convert-crypto-wordarray-to-uint8array
    const dataArray = new Uint8Array(key.sigBytes);
    for (let i = 0x0; i < key.sigBytes; i++) {
      // eslint-disable-next-line no-bitwise
      dataArray[i] = key.words[i >>> 0x2] >>> 0x18 - i % 0x4 * 0x8 & 0xff;
    }
    return new Uint8Array(dataArray);
  }

  public decrypt(encryptedKey: Uint8Array, nonce: Uint8Array, password: string, salt: string): Uint8Array {
    const derivedKey = this.createDerivedKey(password, salt);
    return <Uint8Array>secretbox.open(
      encryptedKey,
      nonce,
      derivedKey
    );
  }

  public encrypt(key: Uint8Array, password: string, salt: string): { nonce: Uint8Array, encryptedKey: Uint8Array } {
    const derivedKey = this.createDerivedKey(password, salt);
    const nonce = randomBytes(secretbox.nonceLength);
    const encryptedKey = secretbox(
      key,
      nonce,
      derivedKey
    );
    return {nonce, encryptedKey};
  }

  public get nonceLength(): number {
    return secretbox.nonceLength;
  }
}
