/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserDocumentDto } from '../models/user-document-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedUserDocumentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findUserDocuments
   */
  static readonly FindUserDocumentsPath = '/api/user-documents';

  /**
   * Finds user documents for a specific user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUserDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUserDocuments$Response(params: {
    userId: number;
    userVerificationId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserDocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserDocumentService.FindUserDocumentsPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('userVerificationId', params.userVerificationId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDocumentDto>>;
      })
    );
  }

  /**
   * Finds user documents for a specific user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findUserDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUserDocuments(params: {
    userId: number;
    userVerificationId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<Array<UserDocumentDto>> {

    return this.findUserDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserDocumentDto>>) => r.body as Array<UserDocumentDto>)
    );
  }

  /**
   * Path part for operation uploadUserDocument
   */
  static readonly UploadUserDocumentPath = '/api/user-documents';

  /**
   * Uploads a new encrypted document and stores it for the given user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadUserDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadUserDocument$Response(params: {
    userId: number;
    encryptionKey: string;
    nonce: string;
    comment?: string;
    fileCreatedAt: string;
    fileModifiedAt: string;
    userVerificationId?: number;
    fileSha1Hash: string;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserDocumentDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserDocumentService.UploadUserDocumentPath, 'post');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('encryptionKey', params.encryptionKey, {});
      rb.query('nonce', params.nonce, {});
      rb.query('comment', params.comment, {});
      rb.query('fileCreatedAt', params.fileCreatedAt, {});
      rb.query('fileModifiedAt', params.fileModifiedAt, {});
      rb.query('userVerificationId', params.userVerificationId, {});
      rb.query('fileSha1Hash', params.fileSha1Hash, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDocumentDto>;
      })
    );
  }

  /**
   * Uploads a new encrypted document and stores it for the given user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadUserDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadUserDocument(params: {
    userId: number;
    encryptionKey: string;
    nonce: string;
    comment?: string;
    fileCreatedAt: string;
    fileModifiedAt: string;
    userVerificationId?: number;
    fileSha1Hash: string;
    'X-API-Version'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<UserDocumentDto> {

    return this.uploadUserDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserDocumentDto>) => r.body as UserDocumentDto)
    );
  }

  /**
   * Path part for operation downloadUserDocument
   */
  static readonly DownloadUserDocumentPath = '/api/user-documents/{id}/file';

  /**
   * Downloads the encrypted document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadUserDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUserDocument$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserDocumentService.DownloadUserDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Downloads the encrypted document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadUserDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUserDocument(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.downloadUserDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation deleteUserDocument
   */
  static readonly DeleteUserDocumentPath = '/api/user-documents/{id}';

  /**
   * Deletes an user document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDocument$Response(params: {
    id: number;
    userId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserDocumentService.DeleteUserDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('userId', params.userId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes an user document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDocument(params: {
    id: number;
    userId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteUserDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
