import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { filter } from 'rxjs/operators';
import { EnvService } from '@core-shared/services/env.service';

@Injectable()
export class UpdateService {

  public readonly isUpdateAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private envService: EnvService,
              private swal: SweetAlert2LoaderService) {
    interval(30000)
      .pipe(filter(() => !this.isUpdateAvailable$.value && !!this.envService.settings))
      .subscribe(() => this.checkForUpdate());
  }

  private checkForUpdate(): void {
    const currentVersion = this.envService.settings!.version;
    this.envService.getEnvSettings()
      .pipe(filter((settings) => settings.version !== currentVersion))
      .subscribe(() => {
        this.isUpdateAvailable$.next(true);
        this.showUpdateDialog();
      });
  }

  public showUpdateDialog(): void {
    this.swal.swal.then((swal) => {
      swal.fire({
        title: 'Eine Aktualisierung ist verfügbar.',
        text: 'Um neue Funktionen und Erweiterungen nutzen zu können müssen Sie die App aktualisieren.',
        icon: 'info',
        confirmButtonText: 'Aktualisieren',
        cancelButtonText: 'Später erinnern',
        showCancelButton: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          location.reload();
        }
      });
    });
  }

}
