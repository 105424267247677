import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '@core-shared/services/session.service';
import { BootstrapGuard } from '../../dashboard/bootstrap-guard.service';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {

  constructor(private sessionService: SessionService,
              private toastr: ToastrService,
              private bootstrapGuard: BootstrapGuard) {
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await firstValueFrom(this.bootstrapGuard.bootstrappingState.pipe(filter((loadingState) => loadingState === 'success')));
    if (!this.sessionService.$sessionInfo.value) {
      this.toastr.info('Sitzung abgelaufen');
      console.debug(state.url);
      this.sessionService.signOut(state.url);
      return false;
    }
    return true;
  }


}
