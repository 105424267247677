/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatePrebidRequestDto } from '../models/create-prebid-request-dto';
import { FindPrebidsResponseDto } from '../models/find-prebids-response-dto';
import { Operation } from '../models/operation';
import { PrebidDto } from '../models/prebid-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedPrebidService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findPrebids
   */
  static readonly FindPrebidsPath = '/api/prebids';

  /**
   * Finds prebids by a given filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findPrebids()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPrebids$Response(params: {
    auc_state?: string;

    /**
     * Comma separated list of auction ids.
     */
    auc_id?: string;
    cat_id?: string;
    part_id?: string;
    lot_number?: string;
    bidder_number?: number;
    prebid_state?: string;
    bidder_name?: string;
    source?: string;
    user_greylist?: string;
    show_hidden?: boolean;
    '$sortby': string;
    '$sortdir': string;
    '$page': number;
    '$maxpagesize': number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FindPrebidsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedPrebidService.FindPrebidsPath, 'get');
    if (params) {
      rb.query('auc_state', params.auc_state, {});
      rb.query('auc_id', params.auc_id, {});
      rb.query('cat_id', params.cat_id, {});
      rb.query('part_id', params.part_id, {});
      rb.query('lot_number', params.lot_number, {});
      rb.query('bidder_number', params.bidder_number, {});
      rb.query('prebid_state', params.prebid_state, {});
      rb.query('bidder_name', params.bidder_name, {});
      rb.query('source', params.source, {});
      rb.query('user_greylist', params.user_greylist, {});
      rb.query('show_hidden', params.show_hidden, {});
      rb.query('$sortby', params['$sortby'], {});
      rb.query('$sortdir', params['$sortdir'], {});
      rb.query('$page', params['$page'], {});
      rb.query('$maxpagesize', params['$maxpagesize'], {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindPrebidsResponseDto>;
      })
    );
  }

  /**
   * Finds prebids by a given filter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findPrebids$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPrebids(params: {
    auc_state?: string;

    /**
     * Comma separated list of auction ids.
     */
    auc_id?: string;
    cat_id?: string;
    part_id?: string;
    lot_number?: string;
    bidder_number?: number;
    prebid_state?: string;
    bidder_name?: string;
    source?: string;
    user_greylist?: string;
    show_hidden?: boolean;
    '$sortby': string;
    '$sortdir': string;
    '$page': number;
    '$maxpagesize': number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<FindPrebidsResponseDto> {

    return this.findPrebids$Response(params,context).pipe(
      map((r: StrictHttpResponse<FindPrebidsResponseDto>) => r.body as FindPrebidsResponseDto)
    );
  }

  /**
   * Path part for operation createPrebid
   */
  static readonly CreatePrebidPath = '/api/prebids';

  /**
   * Creates a prebid.
   *
   * Creates a prebid for a given user, optionally creating a new bidder, if necessary.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPrebid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPrebid$Response(params: {
    'X-API-Version'?: string;
    body: CreatePrebidRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PrebidDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedPrebidService.CreatePrebidPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrebidDto>;
      })
    );
  }

  /**
   * Creates a prebid.
   *
   * Creates a prebid for a given user, optionally creating a new bidder, if necessary.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPrebid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPrebid(params: {
    'X-API-Version'?: string;
    body: CreatePrebidRequestDto
  },
  context?: HttpContext

): Observable<PrebidDto> {

    return this.createPrebid$Response(params,context).pipe(
      map((r: StrictHttpResponse<PrebidDto>) => r.body as PrebidDto)
    );
  }

  /**
   * Path part for operation patchPrebid
   */
  static readonly PatchPrebidPath = '/api/prebids/{id}';

  /**
   * Patches a prebid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPrebid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPrebid$Response(params: {
    id: number;
    allowExceedingCreditLimit?: boolean;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PrebidDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedPrebidService.PatchPrebidPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('allowExceedingCreditLimit', params.allowExceedingCreditLimit, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrebidDto>;
      })
    );
  }

  /**
   * Patches a prebid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPrebid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPrebid(params: {
    id: number;
    allowExceedingCreditLimit?: boolean;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<PrebidDto> {

    return this.patchPrebid$Response(params,context).pipe(
      map((r: StrictHttpResponse<PrebidDto>) => r.body as PrebidDto)
    );
  }

}
