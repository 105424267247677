import { Component } from '@angular/core';
import { BaseComponent } from 'inova-shared';
import { BootstrapGuard } from './dashboard/bootstrap-guard.service';
import { UpdateService } from '@core-shared/services/update.service';

@Component({
  selector: 'bo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {

  constructor(updateService: UpdateService,           // inject to init
              private bootstrapGuard: BootstrapGuard  // inject to init
  ) {
    super();
  }


  public get loadingState(): 'loading' | 'success' | 'error' {
    return this.bootstrapGuard.bootstrappingState.value;
  }
}
