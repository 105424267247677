/* tslint:disable */
/* eslint-disable */
export enum ImportAuctionStartingBidCsvConflictType {
  None = 'none',
  MissingHeader = 'missing_header',
  InvalidHeader = 'invalid_header',
  UnexpectedColumnCount = 'unexpected_column_count',
  UnexpectedColumnNames = 'unexpected_column_names',
  NoRows = 'no_rows'
}
