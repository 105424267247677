/* tslint:disable */
/* eslint-disable */
export enum GlobalGrant {
  Administrator = 'administrator',
  AuctionManager = 'auction_manager',
  UserManager = 'user_manager',
  BidsManager = 'bids_manager',
  Connector = 'connector',
  SystemAdministrator = 'system_administrator'
}
