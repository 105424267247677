import { AfterViewInit, Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'auex-bootstrap-error',
  templateUrl: './bootstrap-error.component.html',
  styleUrls: ['./bootstrap-error.component.scss']
})
export class BootstrapErrorComponent implements AfterViewInit {

  @ViewChild(SwalComponent) public swal?: SwalComponent;

  public lang: 'de' | 'en' = 'en';
  public disabled: boolean = true;

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) platformId: string, private translate: TranslateService) {
    const userLang = this.translate.getBrowserCultureLang()?.split('-')[0];
    if (userLang === 'de' || userLang === 'en') {
      this.lang = userLang;
    }
  }


  public ngAfterViewInit(): void {
    this.swal?.fire();
    of(null).pipe(delay(2000)).subscribe(() => this.disabled = false);
  }

  public reload(): void {
    this.document.location.reload();
  }
}
