/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LiveBiddingMetricsDto } from '../models/live-bidding-metrics-dto';
import { LotMetricsDto } from '../models/lot-metrics-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedMetricsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLiveBiddingMetrics
   */
  static readonly GetLiveBiddingMetricsPath = '/api/metrics/live-bidding';

  /**
   * Retrieves live-bidding metrics for a given time range.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveBiddingMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveBiddingMetrics$Response(params?: {
    fromDate?: string;
    toDate?: string;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LiveBiddingMetricsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedMetricsService.GetLiveBiddingMetricsPath, 'get');
    if (params) {
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveBiddingMetricsDto>;
      })
    );
  }

  /**
   * Retrieves live-bidding metrics for a given time range.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLiveBiddingMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveBiddingMetrics(params?: {
    fromDate?: string;
    toDate?: string;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<LiveBiddingMetricsDto> {

    return this.getLiveBiddingMetrics$Response(params,context).pipe(
      map((r: StrictHttpResponse<LiveBiddingMetricsDto>) => r.body as LiveBiddingMetricsDto)
    );
  }

  /**
   * Path part for operation getLotMetrics
   */
  static readonly GetLotMetricsPath = '/api/metrics/lots';

  /**
   * Retrieves lot metrics for a given time range.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLotMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLotMetrics$Response(params?: {
    fromDate?: string;
    toDate?: string;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LotMetricsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedMetricsService.GetLotMetricsPath, 'get');
    if (params) {
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LotMetricsDto>;
      })
    );
  }

  /**
   * Retrieves lot metrics for a given time range.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLotMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLotMetrics(params?: {
    fromDate?: string;
    toDate?: string;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<LotMetricsDto> {

    return this.getLotMetrics$Response(params,context).pipe(
      map((r: StrictHttpResponse<LotMetricsDto>) => r.body as LotMetricsDto)
    );
  }

}
