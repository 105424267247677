/* tslint:disable */
/* eslint-disable */
export enum SignInResult {
  Success = 'success',
  SecondFactorRequired = 'second_factor_required',
  Failed = 'failed',
  RegistrationRequired = 'registration_required',
  LockedOut = 'locked_out',
  Disabled = 'disabled',
  ActivationPending = 'activation_pending'
}
