import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ResourceService } from '@core-shared/services/resource.service';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {

  constructor(private ressourceService: ResourceService) {
  }

  public handle(params: MissingTranslationHandlerParams): string {
    // if (environment.production) {
    // this.ressourceService.addMissingTranslationKey(params.translateService.currentLang, params.key).subscribe();
    // }
    return '**' + params.key + '**';
  }

}
