/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExternalVerificationInfoDto } from '../models/external-verification-info-dto';
import { Operation } from '../models/operation';
import { UserProfileDto } from '../models/user-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedUserProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserProfile
   */
  static readonly GetUserProfilePath = '/api/user-profile';

  /**
   * Gets the user-profile of the currently signed in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfile$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserProfileService.GetUserProfilePath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileDto>;
      })
    );
  }

  /**
   * Gets the user-profile of the currently signed in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfile(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<UserProfileDto> {

    return this.getUserProfile$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserProfileDto>) => r.body as UserProfileDto)
    );
  }

  /**
   * Path part for operation patchUserProfile
   */
  static readonly PatchUserProfilePath = '/api/user-profile';

  /**
   * Updates the users profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchUserProfile$Response(params: {
    securityToken?: string;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserProfileService.PatchUserProfilePath, 'patch');
    if (params) {
      rb.query('securityToken', params.securityToken, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileDto>;
      })
    );
  }

  /**
   * Updates the users profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchUserProfile(params: {
    securityToken?: string;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<UserProfileDto> {

    return this.patchUserProfile$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserProfileDto>) => r.body as UserProfileDto)
    );
  }

  /**
   * Path part for operation getExternalVerificationInfo
   */
  static readonly GetExternalVerificationInfoPath = '/api/user-profile/external-verification-info';

  /**
   * Gets information about an active external verification of the currently signed in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalVerificationInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalVerificationInfo$Response(params: {
    token: string;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExternalVerificationInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserProfileService.GetExternalVerificationInfoPath, 'get');
    if (params) {
      rb.query('token', params.token, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExternalVerificationInfoDto>;
      })
    );
  }

  /**
   * Gets information about an active external verification of the currently signed in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalVerificationInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalVerificationInfo(params: {
    token: string;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<ExternalVerificationInfoDto> {

    return this.getExternalVerificationInfo$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExternalVerificationInfoDto>) => r.body as ExternalVerificationInfoDto)
    );
  }

  /**
   * Path part for operation resendVerificationMail
   */
  static readonly ResendVerificationMailPath = '/api/user-profile/resend-verification-mail';

  /**
   * Resends the verification mail for the currently requested/active verification of the currently signed in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendVerificationMail()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendVerificationMail$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedUserProfileService.ResendVerificationMailPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Resends the verification mail for the currently requested/active verification of the currently signed in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resendVerificationMail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendVerificationMail(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.resendVerificationMail$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
