/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateVeetingMeetingRequestDto } from '../models/create-veeting-meeting-request-dto';
import { VeetingMeetingDto } from '../models/veeting-meeting-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneratedVeetingMeetingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVeetingMeeting
   */
  static readonly GetVeetingMeetingPath = '/api/veeting-meeting';

  /**
   * Gets a veeting meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVeetingMeeting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVeetingMeeting$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VeetingMeetingDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedVeetingMeetingService.GetVeetingMeetingPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VeetingMeetingDto>;
      })
    );
  }

  /**
   * Gets a veeting meeting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVeetingMeeting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVeetingMeeting(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<VeetingMeetingDto> {

    return this.getVeetingMeeting$Response(params,context).pipe(
      map((r: StrictHttpResponse<VeetingMeetingDto>) => r.body as VeetingMeetingDto)
    );
  }

  /**
   * Path part for operation createVeetingMeeting
   */
  static readonly CreateVeetingMeetingPath = '/api/veeting-meeting';

  /**
   * Starts a veeting meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVeetingMeeting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVeetingMeeting$Response(params: {
    'X-API-Version'?: string;
    body: CreateVeetingMeetingRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VeetingMeetingDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedVeetingMeetingService.CreateVeetingMeetingPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VeetingMeetingDto>;
      })
    );
  }

  /**
   * Starts a veeting meeting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVeetingMeeting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVeetingMeeting(params: {
    'X-API-Version'?: string;
    body: CreateVeetingMeetingRequestDto
  },
  context?: HttpContext

): Observable<VeetingMeetingDto> {

    return this.createVeetingMeeting$Response(params,context).pipe(
      map((r: StrictHttpResponse<VeetingMeetingDto>) => r.body as VeetingMeetingDto)
    );
  }

  /**
   * Path part for operation closeVeetingMeeting
   */
  static readonly CloseVeetingMeetingPath = '/api/veeting-meeting';

  /**
   * Ends a veeting meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeVeetingMeeting()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeVeetingMeeting$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedVeetingMeetingService.CloseVeetingMeetingPath, 'delete');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Ends a veeting meeting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeVeetingMeeting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeVeetingMeeting(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.closeVeetingMeeting$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
